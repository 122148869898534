<template>
  <div>
    <div v-if="loading">
      <div class="row">
        <div class="col-sm-3">
          <div class="card shadow">
            <div class="card-body">
              <v-skeleton-loader type="chip"></v-skeleton-loader> <br />
              <v-skeleton-loader type="text@3"></v-skeleton-loader>
            </div>
          </div>
        </div>
        <div class="col-sm-9">
          <div class="card shadow">
            <div class="card-body">
              <v-skeleton-loader type="heading"></v-skeleton-loader> <br />
              <v-skeleton-loader type="sentences@3"></v-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component :is="view" :status="xhr.status" v-else></component>
  </div>
</template>

<script>
import statusActive from "@/views/pages/product/emails/product-email-active.vue";
import statusNonActive from "@/views/pages/product/slot/slotLayananNonActive.vue";
import { breadcrumb, loading, ApiURL } from "@/common/mixins/general.js";

export default {
  name: "productDomainSingleActive",
  mixins: [breadcrumb, loading, ApiURL],
  components: { statusActive, statusNonActive },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      loading: true,
      /**
       * -----------------------------------------
       * tampung data API
       * -----------------------------------------
       */
      xhr: {
        status: {
          status: null
        }
      }
    };
  },
  methods: {
    get_status: function() {
      this.http
        .get(this.end.email.status, this.id)
        .then(({ data }) => {
          this.xhr.status = data.data.status;
          this.mx_breadcrumbEmail([{ title: data.data.status.domain }]);
        })
        .catch(({ response }) => {
          this.xhr.status = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    view: function() {
      return this.xhr.status.status === "Active"
        ? "statusActive"
        : "statusNonActive";
    }
  },
  watch: {
    /**
     * ----------------------------------------
     * jika inject domain id url
     * ----------------------------------------
     */
    $route(to) {
      this.get_status(to.params.id);
    }
  },
  mounted() {
    this.get_status();
  }
};
</script>
