<template>
  <div class="row">
    <div class="col-sm-3">
      <!-- status account -->
      <RWCard title="Manual" v-if="status.vendor === 'titan'" :bodyFit="true">
        <template #body>
          <b-list-group flush>
            <b-list-group-item
              target="_blank"
              href="https://www.rumahweb.com/journal/panduan-setting-outlook-untuk-titan/"
              class="text-truncate"
              >Setting IMAP Outlook</b-list-group-item
            >
            <b-list-group-item
              target="_blank"
              href="https://www.rumahweb.com/journal/panduan-setting-dns-untuk-titan/"
              class="text-truncate"
              >Panduan Setting DNS Untuk Titan</b-list-group-item
            >
            <b-list-group-item
              target="_blank"
              href="https://www.rumahweb.com/journal/cara-setting-mx-record-di-cpanel/"
              class="text-truncate"
              >Cara Setting MX Record di cPanel</b-list-group-item
            >
            <b-list-group-item target="_blank" href="#" class="text-truncate"
              >List masih dummy</b-list-group-item
            >
          </b-list-group>
        </template>
      </RWCard>
      <RWCard title="Information" v-if="status.vendor !== 'titan'">
        <template #body>
          <div v-if="loading.status">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
          </div>
          <div v-else>
            <p>
              Status :
              <b class="text-success">{{ status.status }}</b>
              <br />
              Recurring :
              <b>{{ status.recurring }}</b> <br />
              Due Date :
              <b>{{ status.duedate }}</b> <br />
              Account Email :
              <b>{{ status.account }} seat</b>
              <br />
              Price : <b>Rp{{ status.price }}</b>
              <br />
            </p>
          </div>
        </template>
      </RWCard>
      <!-- end status account -->

      <!-- admin account -->
      <RWCard title="Admin account" v-if="status.vendor !== 'titan'">
        <template #body>
          <v-skeleton-loader
            type="paragraph"
            v-if="loading.account"
          ></v-skeleton-loader>
          <div v-else>
            <p v-if="success.account">
              URL :
              <b
                ><a :href="xhr.account.url">{{ xhr.account.url }}</a></b
              >
              <br />
              Username :
              {{ xhr.account.post.username }}
              <br />
              Password :
              {{
                showPassword
                  ? decode_password(xhr.account.post.password)
                  : "***********"
              }}
              <i
                class="fas fa-eye rw-cursor-pointer"
                id="show-password"
                @click="show_password()"
              ></i>
              <i
                class="fas fa-clone ml-2 rw-cursor-pointer"
                id="copy-password"
                @click="copy_password()"
              ></i>

              <b-tooltip target="copy-password" variant="warning"
                >Copy password</b-tooltip
              >
              <b-tooltip target="show-password" variant="danger">{{
                label.toggle_password
              }}</b-tooltip>
            </p>
            <b-alert show variant="danger" v-else>
              <span v-if="!success.account">
                {{ xhr.account.message }}
              </span>
              <span v-else> Status email {{ xhr.status.status }} </span>
            </b-alert>
          </div>
        </template>
      </RWCard>
      <!-- end admin account -->
    </div>

    <!-- banner -->
    <div class="col-sm-9">
      <b-skeleton-img v-if="loading.account"></b-skeleton-img>
      <RWCard :title="status.name + ' - ' + status.domain" v-else>
        <template v-slot:body>
          <div
            class="well"
            style="
              background-image: url('https://cdn01.rumahweb.com/whmcs/img/rwpanel-banner.jpg');
              background-color: #33aae2;
              background-repeat: no-repeat;
              background-position: 50% 0;
            "
          >
            <p style="margin-top: 260px" class="text-center"></p>
            &nbsp;
            <div class="text-center" v-if="status.vendor === 'titan'">
              <div v-if="success.account">
                <a
                  :href="xhr.account.url"
                  class="btn btn-primary"
                  target="_blank"
                  ><strong>Login to Titan Mail</strong></a
                >
              </div>
              <div v-else>
                <button class="btn btn-danger">
                  <strong>{{ xhr.account.message }}l</strong>
                </button>
              </div>
            </div>
            <br />
          </div>
        </template>
      </RWCard>

      <div class="row" v-if="status.vendor === 'titan'">
        <div class="col-sm-4">
          <RWCard title="Info Seat">
            <template #body>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore,
              explicabo!
            </template>
          </RWCard>
        </div>
        <div class="col-sm-4">
          <RWCard title="Detail MX">
            <template #body>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore,
              explicabo!
            </template>
          </RWCard>
        </div>
        <div class="col-sm-4">
          <RWCard title="Tambah seat">
            <template #body>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore,
              explicabo!
            </template>
          </RWCard>
        </div>
      </div>
    </div>
    <!-- end banner -->
  </div>
</template>

<script>
import copy from "clipboard-copy";
import RWCard from "@/views/partials/content/Portlet.vue";
import { breadcrumb, ApiURL, notif } from "@/common/mixins/general.js";
export default {
  mixins: [breadcrumb, ApiURL, notif],
  components: { RWCard },
  props: {
    status: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showPassword: false,
      // vendor_allowed: ["titan", "gapps", "alimail"],
      label: {
        toggle_password: "Show Password"
      },
      loading: {
        status: true,
        account: true
      },
      xhr: {
        status: {},
        account: {}
      },
      success: {
        status: false,
        account: false
      }
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * get account login email
     * --------------------------------------------------------------
     */
    get_account: function() {
      this.http
        .get(this.end.email.account, this.status.id)
        .then(({ data }) => {
          this.xhr.account = data.data.account;
          this.success.account = true;
        })
        .catch(error => {
          this.xhr.account = error;
        })
        .finally(() => {
          this.loading.account = false;
        });
    },

    /**
     * --------------------------------------------------------------
     * toggle showing password to customer
     * --------------------------------------------------------------
     */
    show_password: function() {
      this.showPassword = !this.showPassword;
      this.label.toggle_password = this.showPassword
        ? "Hide Password"
        : "Show Password";
    },

    /**
     * --------------------------------------------------------------
     * copy password to customer
     * --------------------------------------------------------------
     */
    copy_password: function() {
      copy(this.decode_password(this.xhr.account.post.password));
      this.mx_toast("Password is copied", "", { variant: "success" });
    },

    /**
     * --------------------------------------------------------------
     * password dari whmcs encode base64 jadi harus decode dulu
     * --------------------------------------------------------------
     */
    decode_password: function(password) {
      return atob(password);
    }
  },
  mounted() {
    this.get_account();
    if (this.status.vendor === "titan") {
      this.get_account();
    }

    setTimeout(() => {
      this.loading.status = false;
    }, 1000);
  }
};
</script>
